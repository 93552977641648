import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar/Navbar';
import LeftNavbar from './Navbar/LeftNavbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const API_URL = process.env.REACT_APP_API_ORIGIN;
const drawerWidth = 240;

export default function FilteredUser() {
  const [open, setOpen] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userData, setUserData] = useState(null);
  const [consultantList, setConsultantList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedAddUsers, setSelectedAddUsers] = useState([]);
  const [selectedDeleteUsers, setSelectedDeleteUsers] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { groupId, groupName } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/consultant/getUserList`);
        const responsedata = await response.json();
        setUserData(responsedata);

        const data = responsedata.filter(user => user.groupId === parseInt(groupId));
        const consultantData = [];
        const clientData = [];
        let cnt1 = 1;
        let cnt2 = 1;

        for (const item of data) {
          if (item.user_type === 'consultant') {
            item.id = cnt1;
            consultantData.push(item);
            cnt1 += 1;
          } else if (item.user_type === 'client') {
            item.id = cnt2;
            clientData.push(item);
            cnt2 += 1;
          }
        }
        setConsultantList(consultantData);
        setClientList(clientData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [groupId]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAddDialogOpen = () => {
    setSelectedAddUsers([]);
    setOpenAddDialog(true);
  };

  const handleAddDialogClose = () => {
    setOpenAddDialog(false);
  };

  const handleDeleteDialogOpen = () => {
    setSelectedDeleteUsers([]);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleAddUser = async () => {
    try {
      for (const username of selectedAddUsers) {
        const user = userData.find(user => user.username === username);
        await axios.post(`${API_URL}/auth/groupid`, {
          uid: user.uid,
          groupId: parseInt(groupId)
        });
      }
      handleSnackbarOpen('사용자가 성공적으로 추가되었습니다.');
      setOpenAddDialog(false);
      window.location.reload();
    } catch (error) {
      handleSnackbarOpen(`사용자 추가에 실패했습니다: ${error.message}`);
    }
  };

  const handleDeleteUser = async () => {
    try {
      for (const username of selectedDeleteUsers) {
        const user = userData.find(user => user.username === username);
        await axios.post(`${API_URL}/auth/groupid`, {
          uid: user.uid,
          groupId: 0
        });
      }
      handleSnackbarOpen('사용자가 성공적으로 삭제되었습니다.');
      setOpenDeleteDialog(false);
      window.location.reload();
    } catch (error) {
      handleSnackbarOpen(`사용자 삭제에 실패했습니다: ${error.message}`);
    }
  };

  const handleToggleAddUser = (username) => {
    setSelectedAddUsers(prev =>
      prev.includes(username)
        ? prev.filter(user => user !== username)
        : [...prev, username]
    );
  };

  const handleToggleDeleteUser = (username) => {
    setSelectedDeleteUsers(prev =>
      prev.includes(username)
        ? prev.filter(user => user !== username)
        : [...prev, username]
    );
  };

  const filterUsersForAddition = () =>
    userData ? userData.filter(user => user.groupId === null) : [];

  const filterUsersForDeletion = () =>
    [...consultantList, ...clientList];

  const renderUserListForAddDialog = () =>
    filterUsersForAddition().map(user => (
      <ListItem key={user.username} button onClick={() => handleToggleAddUser(user.username)}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selectedAddUsers.includes(user.username)}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={`${user.nickname} (${user.username})`} />
      </ListItem>
    ));

  const renderUserListForDeleteDialog = () =>
    filterUsersForDeletion().map(user => (
      <ListItem key={user.username} button onClick={() => handleToggleDeleteUser(user.username)}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selectedDeleteUsers.includes(user.username)}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={`${user.nickname} (${user.username})`} />
      </ListItem>
    ));

  const defaultTheme = createTheme();

  const consultantColumns = [
    { field: 'id', headerName: 'Index', width: 90 },
    {
      field: 'nickname',
      headerName: '성명',
      width: 200,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'ID',
      width: 180,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'phone_number',
      headerName: '연락처',
      width: 200,
      editable: false,
    },
  ];

  const clientColumns = [
    { field: 'id', headerName: 'Index', width: 90 },
    {
      field: 'nickname',
      headerName: '성명',
      width: 200,
      editable: false,
    },
    {
      field: 'username',
      headerName: 'ID',
      width: 180,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'phone_number',
      headerName: '연락처',
      width: 200,
      editable: false,
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} toggleDrawer={toggleDrawer} />
        <LeftNavbar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={6} >
              <Typography variant="h3" sx={{ mb: 2 }}>
                소속명 : {groupName}
              </Typography>
              <hr></hr>
            </Grid>
            <br></br>
            <Grid item xs={6} >
              <Typography variant="h5" sx={{ mb: 2 }}>
                컨설턴트
              </Typography>
              {consultantList.length === 0 ? (
                <p>현재 컨설턴트가 없습니다.</p>
              ) : (
                <Box sx={{ backgroundColor: 'white', height: 400, width: '100%', margin: 'auto' }}>
                  <DataGrid
                    rows={consultantList}
                    columns={consultantColumns}
                    pageSize={100}
                    disableRowSelectionOnClick
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={6} sx={{ marginTop: 5 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                고객
              </Typography>
              {clientList.length === 0 ? (
                <p>현재 고객이 없습니다.</p>
              ) : (
                <Box sx={{ backgroundColor: 'white', height: 400, width: '100%', margin: 'auto' }}>
                  <DataGrid
                    rows={clientList}
                    columns={clientColumns}
                    pageSize={100}
                    disableRowSelectionOnClick
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <Button variant="contained" color="primary" onClick={handleAddDialogOpen} sx={{ mr: 2 }}>
                멤버 추가
              </Button>
              <Button variant="contained" color="secondary" onClick={handleDeleteDialogOpen}>
                멤버 삭제
              </Button>
            </Grid>
          </Container>

          {/* 멤버 추가 다이얼로그 */}
          <Dialog open={openAddDialog} onClose={handleAddDialogClose}>
            <DialogTitle>멤버 추가</DialogTitle>
            <DialogContent>
              <DialogContentText>
                추가할 멤버를 선택하세요.
              </DialogContentText>
              <List>
                {renderUserListForAddDialog()}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddDialogClose} color="primary">
                취소
              </Button>
              <Button onClick={handleAddUser} color="primary">
                추가하기
              </Button>
            </DialogActions>
          </Dialog>

          {/* 멤버 삭제 다이얼로그 */}
          <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
            <DialogTitle>멤버 삭제</DialogTitle>
            <DialogContent>
              <DialogContentText>
                삭제할 멤버를 선택하세요.
              </DialogContentText>
              <List>
                {renderUserListForDeleteDialog()}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                취소
              </Button>
              <Button onClick={handleDeleteUser} color="primary">
                삭제하기
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >{snackbarMessage}</Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
