import React, { useState, useEffect } from 'react';
import { TableContainer, Paper, Button, Checkbox } from '@mui/material';
import '../../styles/Document2Table.css';
import axios from 'axios';
import Fab from '@mui/material/Fab';
import RightArrow from '@mui/icons-material/KeyboardArrowRight';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
const API_URL = process.env.REACT_APP_API_ORIGIN;



const Document2 = ({setChatbotStep, category1, category2, setCategory2, moveToNextStep,updateBusinessType, offeringValues}) => {
  const uid = localStorage.getItem('uid');
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [saveSpinner, setSaveSpinner] = useState(false);
  const [answerStatus, setAnswerStatus] = useState(0);//모두 답변됐는지
  const [checkBoxValue, setCheckBoxValue] = useState({
    블루오션: false,
    기존시장: false,
    신규고객: false,
    신제품: false
  });
  const selectedOfferings = offeringValues.split(',');

  // console.log("오퍼링",offeringValues);
  // offeringCheckBoxValue 초기화
  const [offeringCheckBoxValue, setOfferingCheckBoxValue] = useState({
    B2B: selectedOfferings.includes('B2B'),
    B2C: selectedOfferings.includes('B2C'),
    하드웨어: selectedOfferings.includes('하드웨어'),
    소프트웨어: selectedOfferings.includes('소프트웨어'),
    서비스: selectedOfferings.includes('서비스'),
  });

  useEffect(() => {
    if (category2 && checkBoxValue.hasOwnProperty(category2)) {
      setCheckBoxValue(prevState => ({
        ...prevState,
        [category2]: true
      }));
    }
  }, [category2]); 

  useEffect(() => {
    const hasTrueInCheckBoxValue = Object.values(checkBoxValue).some(value => value === true);
    const hasTrueInOfferingCheckBoxValue = Object.values(offeringCheckBoxValue).some(value => value === true);
    if (hasTrueInCheckBoxValue && hasTrueInOfferingCheckBoxValue)
    {
      updateChatbotStepAtIndex(1,2)//챗봇 안내메세지 보내기 위해 상태 변경
      setAnswerStatus(1);
    }
    else if (hasTrueInCheckBoxValue)
      updateChatbotStepAtIndex(1,1)

  }, [checkBoxValue, offeringCheckBoxValue]);

  const handleOfferingCheckbox = (checkedValue) => {
    setOfferingCheckBoxValue(prevState => ({
      ...prevState,
      [checkedValue]: !prevState[checkedValue]
    }));
  };
  

  const handleCheckbox = (checkedValue) => {
    console.log("boxclick", checkedValue);
    console.log("check",checkBoxValue);
    setCheckBoxValue({
      블루오션: checkedValue === "블루오션",
      기존시장: checkedValue === "기존시장",
      신규고객: checkedValue === "신규고객",
      신제품: checkedValue === "신제품"
    });
  };

  const updateChatbotStepAtIndex = (index, newValue) => {
    setChatbotStep((prevSteps) => {
      // 이전 상태를 복사하여 새 배열을 생성합니다.
      const updatedSteps = [...prevSteps];
      // 특정 인덱스의 값을 새로운 값으로 업데이트합니다.
      updatedSteps[index] = newValue;
      // 업데이트된 배열을 반환합니다.
      return updatedSteps;
    });
  };

    //제출 dialog
    const ConfirmDialog = ({ onClose, onConfirm }) => {
      const [confirming, setConfirming] = useState(false);
  
      const handleConfirmClick = () => {
        setConfirming(true); // 확인 버튼 클릭 시 스피너 표시
  
        setTimeout(() => {
          onConfirm(); // 실제 작업 수행
          setConfirming(false);
        }, 2000);
      };
      
      return (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>답변을 제출하시겠습니까?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              답변이 최종 저장되고 수정할 수 없습니다.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              취소
            </Button>
            <Button onClick={handleConfirmClick} color="primary" disabled={confirming}>
              {confirming ? <CircularProgress size={24} /> : '확인'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
  
    const AlertDialog = ({ onClose }) => {
      return (
        <Dialog open={showAlert} onClose={onClose}>
  
          <DialogTitle>답변을 완료한 후 제출해주세요.</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Alert severity="warning" >
                답변 목록에 빈 답변이 있습니다.
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
  
    const hadleSaveAnswer = async () => { // 임시 저장버튼
      setSaveSpinner(true)
      await saveAnswersToServer();
      setTimeout(() => {
        setSaveSpinner(false)
        setOpenSnackbar(true)
  
      }, 1000);
    }
  
    const handleSubmit = () => { //제출 버튼
      if (answerStatus === 1) {
        setOpen(true)
      }
      else {
        setShowAlert(true)
      }
    };
  
    const saveAnswersToServer = async () => { //답변 저장 api호출
      try {
        const category2_data = Object.keys(checkBoxValue).filter(key => checkBoxValue[key] === true);
        const businessTypedata = category1 + "_" + category2_data;
        setCategory2(category2_data);
        // console.log("새로운 카테고리",businessTypedata);
        const trueValues = Object.keys(offeringCheckBoxValue)
        .filter(key => offeringCheckBoxValue[key])
        .join(',');
  
      const [businessTypeResponse, offeringTypeResponse] = await Promise.all([
        updateBusinessType(businessTypedata),
        updateOfferingType(trueValues)
      ]);
  
      console.log(businessTypeResponse.data);
      console.log(offeringTypeResponse.data);
  
      } catch (error) {
        console.error('Error submitting businessType:', error);
      }
    };
    const updateOfferingType = async () => {

      const trueValues = Object.keys(offeringCheckBoxValue)
      .filter(key => offeringCheckBoxValue[key])
      .join(',');
      try {
          const response = await axios.post(`${API_URL}/auth/setOfferingType`, {
              uid: uid,
              offeringType: trueValues,
          });
          console.log(response.data);
      } catch (error) {
          console.error('Error submitting offeringType:', error);
      }
    };


    const submitAndMoveNextStep = async () => {
      await saveAnswersToServer();
      
      moveToNextStep();
      setOpen(false);
    }

  return (
    <Paper  sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <div className="table-container">
      <table style={{marginBottom:"20px"}}>
        {/* <thead> */}
        <tbody>

          <tr>
            <th colSpan="2" rowSpan="3" className="tableHeader">시장유형진단</th>
            <th colSpan="2" className="tableHeader">고객층</th>
          </tr>
          <tr>
            <th className="tableHeader">신규</th>
            <th className="tableHeader">기존</th>
          </tr>
          <tr>
            <td className="tableCell">경쟁사들이 목표로 하지 않는 새로운 고객층</td>
            <td className="tableCell">경쟁사들도 목표로 하고 있는 고객층</td>
          </tr>
        {/* </thead> */}
        {/* <tbody> */}
          <tr className="tableRow">
            <th rowSpan="6" className="tableHeader">제품/서비스</th>
            <th className="tableHeader">신규</th>
            <td className="tableCell">
              {/* <Checkbox
                checked={checkBoxValue["블루오션"]}
                onChange={() => handleCheckbox("블루오션")}
              /> */}
              <input
                type="checkbox"
                checked={checkBoxValue["블루오션"]}
                onChange={() =>  handleCheckbox("블루오션")}
              />
            </td>
            <td className="tableCell">
              {/* <Checkbox
                checked={checkBoxValue["신제품"]}
                onChange={() => handleCheckbox("신제품")}
              /> */}
              <input
                type="checkbox"
                checked={checkBoxValue["신제품"]}
                onChange={() =>  handleCheckbox("신제품")}
              />
            </td>
          </tr>
          <tr className="tableRow">
            <td className="tableCell">기존의 오퍼링을 새롭게 혁신한 경우</td>
            <th className="tableHeader">블루 오션 시장</th>
            <th className="tableHeader">신제품 시장</th>
          </tr>
          <tr className="tableRow">
            <td className="tableCell">지금까지 세상에 없는 완전히 새로운 오퍼링</td>
            <td className="tableCell">
              <ul>
                <li>기존시장에 존재하는 오퍼링을 혁신적인 기술로 업그레이드하거나 또는 혁신적인 비즈니스 모델을 통해 새로운 고객층에게 접근</li>
                <li>이를 통해 기존의 경쟁자들이 미처 해결하지 못하거나 보지 못하는 기회를 포착한 경우</li>
                <li>기존 경쟁자들과 직접적 경쟁 발생하지 않음</li>
                <li>지금까지 세상에 존재하지 않았던 전혀 새로운 오퍼링으로 고객을 개척하는 경우</li>
              </ul>
            </td>
            <td className="tableCell">
              <ul>
                <li>기존 고객층에게 제공되었던 오퍼링을 혁신적인 기술로 업그레이드하거나 또는 혁신적인 비즈니스 모델을 통해 접근하여 기존고객에게 접근하는 경우</li>
                <li>그러나 이로 인해 기존 경쟁자들과 직접적 경쟁 발생함</li>
              </ul>
            </td>
          </tr>
          <tr className="tableRow">
            <th className="tableHeader">기존</th>
            <td className="tableCell">
              <input
                type="checkbox"
                checked={checkBoxValue["신규고객"]}
                onChange={() =>  handleCheckbox("신규고객")}
              />
            </td>
            <td className="tableCell">
              <input
                type="checkbox"
                checked={checkBoxValue["기존시장"]}
                onChange={() =>  handleCheckbox("기존시장")}
              />
            </td>
          </tr>
          <tr className="tableRow">
            <td className="tableCell">경쟁사들과 크게 차별화되지 않은 오퍼링</td>
            <th className="tableHeader">신규 고객 시장</th>
            <th className="tableHeader">기존 시장</th>
          </tr>
          <tr className="tableRow">
            <td className="tableCell">기존 오퍼링을 혁신 수준이 아닌 개선 정도 수준으로 업그레이드 한 경우</td>
            <td className="tableCell">
              <ul>
                <li>시존 시장에서 거래된던 오퍼링을 가지고 새로운 고객층 개발</li>
                <li>고객층은 새로우나 오퍼링은 기존 경쟁자와 크게 차별화되지 않음</li>
                <li>해외에서 성공한 오퍼링이나 비즈니스 모델을 복제하여 국내로 들여오거나 그 반대 경우</li>
              </ul>
            </td>
            <td className="tableCell">
              <ul>
                <li>현재 존재하는 시장 - 이미 경쟁자와 고객이 존재하는 시장에 기존 경쟁자와 크게 차별화되지 않은 오퍼링으로 진출하는 경우</li>
              </ul>
            </td>
          </tr>
        {/* </thead> */}

        </tbody>
      </table>

      <table style={{marginBottom:"20px"}}>
        <h4>고객과 오퍼링(제품서비스)</h4>
        <tbody>
          <tr>
            <td rowSpan={2}>고객</td>
              <td style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={offeringCheckBoxValue["B2B"]}
                onChange={() =>  handleOfferingCheckbox("B2B")}
                style={{ marginRight: '10px' }}
              />
              B2B(G)
            </td>
            <td>목표 고객은 기업 혹은 정부기관</td>
          </tr>
          <tr>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={offeringCheckBoxValue["B2C"]}
                onChange={() =>  handleOfferingCheckbox("B2C")}
                style={{ marginRight: '10px' }}
              />
              B2C
            </td>
            <td>목표 고객이 다수의 개인</td>
          </tr>
          <tr>
            <td rowSpan={3}>제품/서비스</td>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={offeringCheckBoxValue["하드웨어"]}
                onChange={() =>  handleOfferingCheckbox("하드웨어")}
                style={{ marginRight: '10px' }}
              />
              하드웨어
            </td>
            <td>제공하는 오퍼링이 유형의 하드웨어</td>
          </tr>
          <tr>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={offeringCheckBoxValue["소프트웨어"]}
                onChange={() =>  handleOfferingCheckbox("소프트웨어")}
                style={{ marginRight: '10px' }}
              />
              소프트웨어
            </td>
            <td>제공하는 오퍼링이 유형의 소프트웨어</td>
          </tr>
          <tr>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={offeringCheckBoxValue["서비스"]}
                onChange={() =>  handleOfferingCheckbox("서비스")}
                style={{ marginRight: '10px' }}
              />
              서비스
            </td>
            <td>제공하는 오퍼링이 무형의 서비스</td>
          </tr>
        </tbody>
      </table>
      <Fab
        varient="extended"
        onClick={hadleSaveAnswer}
        sx={{
          color: 'common.white',
          bgcolor: green[500],
          '&:hover': {
            bgcolor: green[600],
          },
          position: 'fixed',
          width: 100,
          bottom: 30,
          right: 150,
          borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
          boxShadow: '0, 0, 0, 0.5',
        }}
        disabled={saveSpinner} // 작업이 진행 중일 때 버튼을 비활성화합니다.
      >
        {saveSpinner ? <CircularProgress size={24} /> : <span>임시저장</span>} {/* 스피너 또는 텍스트 표시 */}
      </Fab>
      <Fab color="secondary"
        varient="extended"
        onClick={handleSubmit}
        sx={{
          position: 'fixed',
          width: 100,
          bottom: 30,
          right: 30,
          borderRadius: 5, // 직사각형으로 만들기 위해 borderRadius 값을 조절합니다.
          boxShadow: '0, 0, 0, 0.5',
        }} >
        <span>제출</span>
        <RightArrow />
      </Fab>

      <ConfirmDialog onClose={() => setOpen(false)} onConfirm={submitAndMoveNextStep} />
      <AlertDialog onClose={() => setShowAlert(false)} />
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          답변이 저장되었습니다!
        </Alert>
      </Snackbar>

    </div>
    </Paper>
    
  );
};

export default Document2;
